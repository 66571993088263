exports.components = {
  "component---src-page-templates-about-js": () => import("./../../../src/page-templates/about.js" /* webpackChunkName: "component---src-page-templates-about-js" */),
  "component---src-page-templates-business-detail-js": () => import("./../../../src/page-templates/businessDetail.js" /* webpackChunkName: "component---src-page-templates-business-detail-js" */),
  "component---src-page-templates-business-js": () => import("./../../../src/page-templates/business.js" /* webpackChunkName: "component---src-page-templates-business-js" */),
  "component---src-page-templates-contact-js": () => import("./../../../src/page-templates/contact.js" /* webpackChunkName: "component---src-page-templates-contact-js" */),
  "component---src-page-templates-home-js": () => import("./../../../src/page-templates/home.js" /* webpackChunkName: "component---src-page-templates-home-js" */),
  "component---src-page-templates-news-article-js": () => import("./../../../src/page-templates/newsArticle.js" /* webpackChunkName: "component---src-page-templates-news-article-js" */),
  "component---src-page-templates-news-js": () => import("./../../../src/page-templates/news.js" /* webpackChunkName: "component---src-page-templates-news-js" */),
  "component---src-page-templates-portal-js": () => import("./../../../src/page-templates/portal.js" /* webpackChunkName: "component---src-page-templates-portal-js" */),
  "component---src-page-templates-product-js": () => import("./../../../src/page-templates/product.js" /* webpackChunkName: "component---src-page-templates-product-js" */),
  "component---src-page-templates-sustainability-js": () => import("./../../../src/page-templates/sustainability.js" /* webpackChunkName: "component---src-page-templates-sustainability-js" */),
  "component---src-page-templates-terms-js": () => import("./../../../src/page-templates/terms.js" /* webpackChunkName: "component---src-page-templates-terms-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

